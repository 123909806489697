@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.horizontal-dot-loader {
	&__dot {
		background-color: $black;
		float: left;
		height: 6px;
		width: 6px;
		animation-name: bounce_horizontal-dot-loader;
		animation-duration: 1.4575s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		border-radius: 100px;

		&:nth-child(1) {
			animation-delay: 0.2895s;
		}

		&:nth-child(2) {
			animation-delay: 0.6855s;
			margin-left: 3px;
		}

		&:nth-child(3) {
			animation-delay: 0.8785s;
			margin-left: 3px;
		}
	}
}
