@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.dont-leave {
	&__contact {
		font-size: 1.54rem;
		letter-spacing: 0;
		margin-bottom: 20px;
	}

	&__button {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;

		.relative-link {
			padding-right: 20px;
			padding-left: 20px;

			@include gridle_state(sm) {
				width: auto;
			}
		}
	}
}
