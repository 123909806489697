@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/breakpoint.scss";

$typographies: (
	xl10: "xl10",
	xl9: "xl9",
	xl8: "xl8",
	xl7: "xl7",
	xl6: "xl6",
	xl5: "xl5",
	xl4: "xl4",
	xl3: "xl3",
	xl2: "xl2",
	xl: "xl",
	large: "large",
	regular: "regular",
	small: "small",
	xsmall: "xsmall",
	xxsmall: "xxsmall",
);

.typography {
	$this: &;

	@each $typography, $value in $typographies {
		&--#{$typography} {
			font-size: var(--font-size-#{$typography});
			font-family: var(--font-family-#{$typography});
			text-transform: var(--text-transform-#{$typography});
			line-height: 1.2;
		}
	}

	@each $typography, $value in $typographies {
		&--#{$typography}-bold {
			font-size: var(--font-size-bold-#{$typography});
			font-family: var(--font-family-bold-#{$typography});
			font-weight: var(--font-weight-bold-#{$typography});
			text-transform: var(--text-transform-bold-#{$typography});
			line-height: 1.2;
		}
	}

	@media (min-width: $mc) {
		@each $typography, $value in $typographies {
			&--mc-#{$typography} {
				font-size: var(--font-size-#{$typography});
				font-family: var(--font-family-#{$typography});
				text-transform: var(--text-transform-#{$typography});
				line-height: 1.2;
			}
		}

		@each $typography, $value in $typographies {
			&--mc-#{$typography}-bold {
				font-size: var(--font-size-bold-#{$typography});
				font-family: var(--font-family-bold-#{$typography});
				font-weight: var(--font-weight-bold-#{$typography});
				text-transform: var(--text-transform-bold-#{$typography});
				line-height: 1.2;
			}
		}
	}

	@media (min-width: $md) {
		@each $typography, $value in $typographies {
			&--md-#{$typography} {
				font-size: var(--font-size-#{$typography});
				font-family: var(--font-family-#{$typography});
				text-transform: var(--text-transform-#{$typography});
				line-height: 1.2;
			}
		}

		@each $typography, $value in $typographies {
			&--md-#{$typography}-bold {
				font-size: var(--font-size-bold-#{$typography});
				font-family: var(--font-family-bold-#{$typography});
				font-weight: var(--font-weight-bold-#{$typography});
				text-transform: var(--text-transform-bold-#{$typography});
				line-height: 1.2;
			}
		}
	}

	@media (min-width: $lg) {
		@each $typography, $value in $typographies {
			&--lg-#{$typography} {
				font-size: var(--font-size-#{$typography});
				font-family: var(--font-family-#{$typography});
				line-height: 1.2;
			}
		}

		@each $typography, $value in $typographies {
			&--lg-#{$typography}-bold {
				font-size: var(--font-size-bold-#{$typography});
				font-family: var(--font-family-bold-#{$typography});
				font-weight: var(--font-weight-bold-#{$typography});
				line-height: 1.2;
			}
		}
	}
}

strong,
b {
	font-weight: normal;
	display: inline;
}

// dans le fichier _bases.scss, on reset le font-weight les balises strong et b
// pour éviter que ce soit dégeulasse sur safari
strong,
b {
	font-family: var(--font-family-bold), sans-serif;
}
