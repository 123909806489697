@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.paragraphs {
	&__item {
		white-space: pre-wrap;
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	p {
		margin-bottom: 5px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	ul {
		list-style: disc;
		margin-left: 17px;

		li {
			display: list-item;
			margin-bottom: 5px;
		}
	}

	a {
		cursor: pointer;
		text-decoration: underline;
		color: inherit;
		font-family: inherit;
	}
}
