@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.advanced-select {
	&__control {
		border: 1px solid var(--color-gray-500, $input-border-color) !important;
		border-radius: $input-border-radius !important;
		box-shadow: $input-shadow-box;

		&--is-focused {
			border: $input-border !important;
			box-shadow: none !important;
		}

		&--menu-is-open {
			border: $input-border !important;
			box-shadow: none !important;
		}
	}

	&__option {
		background: none !important;

		&--is-selected {
			background: var(--color-active, $active-color) !important;
			color: white;

			.amount {
				&__prefix,
				&__value,
				&__currency {
					color: white;
				}
			}
		}

		@include gridle_state(md) {
			&--is-focused {
				background: $grey-light !important;
				color: $black;

				&.advanced-select__option--is-selected {
					background: var(--color-active, $active-color) !important;
					color: white;

					.amount {
						&__prefix,
						&__value,
						&__currency {
							color: white;
						}
					}
				}
			}
		}
	}
}
