@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$amount-font-weight: normal !default;

.amount {
	display: inline-flex;
	align-items: baseline;

	&__prefix {
		color: black;
		margin-right: 5px;
	}

	&__suffix {
		@extend %font-bold;
		margin-left: 5px;
	}

	&__value {
		@extend %font-bold;
	}
}
