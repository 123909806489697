@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/button";

$button-primary-background-color: $color-primary !default;

.button {
	position: relative;

	&__text {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			height: 20px;
			width: 20px;
			margin-right: 10px;
		}
	}

	&--default {
		@extend %button;
	}

	&--secondary {
		@extend %button--secondary;
	}

	&--primary {
		@extend %button--primary;
	}

	&--invisible {
		@extend %button--invisible;
	}

	&--disabled {
		@extend %button--disabled;
	}
}
